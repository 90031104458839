@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;800&display=swap');

    * {
        font-family: 'Open Sans', sans-serif!important;
    }

    html {
        min-width: 320px;
        overflow-x: hidden;
    }

    html section {
        scroll-snap-align: start;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        text-wrap: balance;
        padding-bottom: 1rem;
    }

    p, div {
        text-wrap: balance;
    }

    .animating-border {
        animation: animatingBorder 15s infinite alternate-reverse;
    }

    .map-container {
        width: 100%;
        aspect-ratio: 16/6;
    }
    
    .map-wrapper {
        height: 100%;
        width: 100%;
    }

    .swiper-wrapper .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: left;
        transition: background-position 500ms;
        
    }

    .swiper-wrapper .swiper-slide-active .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: right;
        transition: background-position 500ms;
    }

    .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
        background-size: 280vh;
    }      

    .mobile-filter {
        display: none;
        transition-duration: 0.3s;
    }
    .mobile-filter.active {
        display: block;
        transition-duration: 0.3s;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 999999;
        padding: 2rem;
    }
    .mobile-filter.close {
        display: none;
    }
    .mobile-filter.active.close {
        display: block;
    }
    #root:has(.mobile-filter.active) {
        overflow: hidden;
        height: 100%;
    }

    @keyframes animatingBorder {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }

    :root{
        --primary-grey: #1D1D1B;
        --primary-yellow: '#DDAE3A';
        --primary-green: '#26684B';
    
        --transition-duration: 0.2s;
    }

    .overflow-inherit {
        overflow: inherit!important;
    }

    .image-list .animated-div{
        width: 100%;
        height: 100%;
    }
    .image-list .animated-div img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }

    .link-element{
        padding: 5px 15px!important;
    }

    .contact-section {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url('/public/images/contact-kegels.jpg');
        background-position: bottom;
    }

    .checkboxGroup-entering,
    .checkboxGroup-exiting {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
    }

    .checkboxGroup-entered {
        opacity: 1;
        max-height: fit-content; /* Dit is een geschatte maximale hoogte. Pas dit aan op basis van je inhoud */
        overflow: hidden;
        transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
    }

      /* slideDown animation with keyframes */
      @keyframes slideDown {
        0% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
        100% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
    }
    .slideDown {
        animation: slideDown var(--transition-duration) ease-in-out;
    }

    /* slideUp animation with keyframes */
    @keyframes slideUp {
        0% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
    }
    .slideUp {
        animation: slideUp var(--transition-duration) ease-in-out;
    }


    .loading-icon {
        animation: rotate 3s infinite;
    }
      
    @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(720deg);
        }
    }
      


    /* Custom Browser compatabilities */
    .safari .team-card {
        width: 90%!important;
        height: 90%!important;
        margin: 0 auto!important;
    }
    .team-card .team-card-img-wrapper {
        width: 100%!important;
        height: 100%!important;
    }



    .CookieConsent {
        background: var(--primary-grey);
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        z-index: 999;
        text-align: center;
        width: 300px;
        -webkit-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
        -moz-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
        box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.46);
    }

    .CookieConsent > div:nth-child(2)  {
        background: rgb(38,104,75);
        background-size: 220%!important;
        background-image: linear-gradient(146deg, rgba(38,104,75,1) 0%, rgba(221,174,58,1) 50%, rgba(38,104,75,1) 70%, rgba(221,174,58,1) 100%);
        background-position: left;
        transition: background-position 500ms!important;
        border-radius: 18px;
        padding: 2px;
        width: fit-content;
        margin: 10px auto;
    }

    .CookieConsent > div:nth-child(2):hover {
        background-position: right;
    }
    
    .CookieConsent > div:nth-child(2) > button {
        background: var(--primary-grey);
        width: 100%;
        border-radius: 20px;
        padding: 4px 20px;
    }

    /* tailwinds media queries */
    @media (min-width: 640px) /*sm*/ {}
    @media (min-width: 768px) /*md*/ {}
    @media (min-width: 1024px) /*lg*/ {}
    @media (min-width: 1280px) /*xl*/ {}
    @media (min-width: 1536px) /*2xl*/ {}

    #root section div h1:not(.overide-static-font), #root div h1:not(.overide-static-font) {
        @apply text-6xl;
    }
    #root section div h2:not(.overide-static-font), #root div h2:not(.overide-static-font) {
        @apply text-5xl;
    }
    #root section div h3:not(.overide-static-font), #root div h3:not(.overide-static-font) {
        @apply text-4xl;
    }
    #root section div h4:not(.overide-static-font), #root div h4:not(.overide-static-font) {
        @apply text-3xl;
    }
    #root section div h5:not(.overide-static-font), #root div h5:not(.overide-static-font) {
        @apply text-2xl;
    }
    #root section div h6:not(.overide-static-font), #root div h6:not(.overide-static-font) {
        @apply text-xl;
    }

    @media (min-width: 568px) and (max-width: 768px) {
        #root section div h1:not(.overide-static-font), #root div h1:not(.overide-static-font) {
            @apply text-5xl;
        }
        #root section div h2:not(.overide-static-font), #root div h2:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h3:not(.overide-static-font), #root div h3:not(.overide-static-font) {
            @apply text-3xl;
        }
        #root section div h4:not(.overide-static-font), #root div h4:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h5:not(.overide-static-font), #root div h5:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h6:not(.overide-static-font), #root div h6:not(.overide-static-font) {
            @apply text-lg;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 230vh;
        }
    }

    @media (min-width: 361px) and (max-width: 567px) {
        #root section div h1:not(.overide-static-font) {
            @apply text-3xl;
        }
        #root section div h2:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h3:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h4:not(.overide-static-font) {
            @apply text-lg;
        }
        #root section div h5:not(.overide-static-font) {
            @apply text-base;
        }
        #root section div h6:not(.overide-static-font) {
            @apply text-base;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 250vh;
        }
    }
    .flags {
        width: 30px!important;
        object-fit: cover;
    }
    .paper ul {
        display: flex;
    }
}
